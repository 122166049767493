import Navbar from '../components/Navbar';
import Portrait from "../assets/portrait.jpg"
import Blume from '../assets/Blume.png';

function About() {
    const isDesktop = window.innerWidth > 768; // Assuming desktop width starts at 768px
    return (
        <div className='min-h-screen pt-20 pb-0' >
            <Navbar active={"about"} />
            <div className='mx-10'>
                <div className='lg:flex items-center'>

                    <div className='lg:block h-96'>
                        <h5 className='text-2xl text-green-900 font-semibold  text-center lg:text-left mb-8'>Über mich</h5>
                        <img src={Portrait} className=' w-60 lg:w-40 h-60 lg:h-72 object-cover rounded-lg shadow-xl mx-auto lg:mx-0 mb-10 lg:mb-0  lg:mt-0 mt-20'></img>
                    </div>

                    <ul className=" lg:ml-9 mt-7">
                        <li className="text-blue-900">Naturverbunden aufgewachsen auf einem entlegenen Bauernhof mit 3 Geschwistern</li>
                        <li className="text-blue-900">Verheiratet und Familienfrau</li>
                        <li className="text-blue-900">Pflegefachfrau Langzeitpflege</li>
                        <li className="text-blue-900">Gemeinnütziges Engagement und ortspolitische Erfahrung (Soziales, Schul- und Flüchtlingswesen)</li>
                        <li className="text-blue-900">Tageshandelsschulabschluss</li>
                        <li className="text-blue-900">Beschäftigungs- und Gedächtnistrainerin von Menschen mit Demenz</li>
                        <li className="text-blue-900">Langjährige Arbeit Pflege, Betreuung und Aktivierung in Wohngruppe</li>
                        <li className="text-blue-900">Tagesheim Demenz erkrankter Menschen</li>
                        <li className="text-blue-900">Zertifikat begleitende Seelsorge bcb</li>
                        <li className="text-blue-900">Diplom Psychosoziale Beraterin bcb/SGfB</li>
                        <li className="text-blue-900">Zertifikat Skulpturen- und Familienstellen bcb</li>
                        <li className="text-blue-900">Weiterbildung Depression</li>
                    </ul>
                    <div className='lg:ml-5 mt-10 lg:mt-0 mb-10 flex justify-center flex-wrap'>
                        <div className='mx-auto'>
                            <h5 className='text-2xl text-green-900 font-semibold mb-2 mt-3 text-center md:text-left'>Freizeit</h5>
                            <ul className="list-inside">
                                <li className="text-green-700">Familie, Freunde, Gemeinschaft</li>
                                <li className="text-green-700">Natur: Entspannung, Inspiration</li>
                                <li className="text-green-700">Metaphern zu Lebensthemen</li>
                                <li className="text-green-700">Musik, Gesang, Malen, Gestalten</li>
                            </ul>
                        </div>
                        <div className="w-full flex justify-center">
                            <img src={Blume} className='w-32 object-contain rounded-lg shadow-xl mt-10'></img>
                        </div>
                    </div>
                </div>
                <h5 className='text-2xl text-blue-900 font-semibold mb-2'>Beratungssetting</h5>
                <h5 className='text-xl text-blue-900'>Einzelberatung</h5>
                <h5 className='text-xl text-blue-900 mb-7'>Paarberatung auf Anfrage</h5>
                <p className='text-blue-900 font-normal'>
                    Terminvereinbarung mit Angabe zu Ihrem Beratungsthema
                </p>
                <p className='text-blue-900 font-normal'>
                    Erstgespräch zum gegenseitigen Kennenlernen 90 Min. (kostenlos, wenn keine weiterführende Beratung erfolgt)
                </p>
                <p className='text-blue-900 font-normal mb-7'>
                    Beratungsvereinbarung, darin festgehalten die Rahmenbedingungen und der Beratungsauftrag
                </p>
                <p className='text-blue-900 font-normal'>
                    Kosten Einzelberatung CHF 100.00 / 60 Min.
                </p>
                <p className='text-blue-900 font-normal mb-10'>
                    <span className='hidden lg:inline-block'>Studierende</span><span className='inline-block lg:hidden'>Stud.</span>/AHV-Bezüger CHF 80.00 / 60 Min.
                </p>
                <div>
                    <h5 className='text-2xl text-blue-900 font-semibold mb-2'>Anmeldung</h5>
                    <p className='text-blue-900 font-normal'>Susanne Knöri</p>
                    <p className='text-blue-900 font-normal'>Burgfeldweg 8</p>
                    <p className='text-blue-900 font-normal'>3612 Steffisburg/Thun</p>
                    <p className='text-blue-900 font-normal'>Phone: 079 562 75 77</p>
                    <p className='text-blue-900 font-normal'>Mail: <a href='mailto:susanne.knoeri@bluewin.ch' className='underline'>susanne.knoeri@bluewin.ch</a></p>
                </div>
                <div className='mt-5 md:flex'>
                    <div className='mr-10'>
                        <h5 className='text-2xl text-blue-900 font-semibold mb-2'>Beratungspraxis</h5>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2733.0150873689427!2d7.6263635760365736!3d46.76459907112492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478fb2e00f84d781%3A0x39053a7e00dd977e!2sBurgfeldweg%208%2C%203612%20Steffisburg!5e0!3m2!1sen!2sch!4v1687968899787!5m2!1sen!2sch"
                            width={isDesktop ? '500' : '300'}
                            height="300"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    <div className='mt-10'>
                        <h5 className='text-2xl text-blue-900 font-semibold mb-2'>Anreise Bus</h5>
                        <p className='text-blue-900 font-normal'>
                            Ab Bahnhof Thun Nr. 1 Richtung Steffisburg Flühli, Haltestellte Glockenthalerhof.
                            Zu Fuss wenige Meter zurück Richtung Stadt, rechts über Feldweg nach Burgfeldweg.
                            Kleines gelbes Haus rechts umgehen, einige Meter zwischen Mehrfam.- Haus und dem Feld entlang. Eingang links. Bitte klingeln: Sein.um
                        </p>
                        <h5 className='text-2xl text-blue-900 font-semibold mb-2 mt-10'>Anreise Auto</h5>
                        <p className='text-blue-900 font-normal'>
                            Von Autobahnzubringer Bernstr. Richtung Thun. Nach Kyburgkreisel li abbiegen in Burgfeldweg. (Firmenschild Elektor Johner)
                            Nach ca. 100 m links einbiegen,
                            zwischen Garage und kleinem gelbem Haus. Besucher-PP vorhanden
                        </p>
                    </div>
                </div>
            </div>
            <div className='h-48 bg-gradient-to-b from-white to-green-100 flex items-center justify-center'>
                <h5 className='text-4xl text-blue-900 font-semibold mb-2 text-center'>Ich freue mich auf Sie!</h5>
            </div>
        </div>
    )
}

export default About