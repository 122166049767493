import Navbar from '../components/Navbar';
import BildPerle from '../assets/BildPerle.png';
import Beratungsraum from '../assets/Beratungsraum.png';
import farbigerPunkt from '../assets/farbigerPunkt.png';
import sgfb_stempel from "../assets/sgfb_stempel.png"
import bcbLogo from "../assets/bcb-logo.jpeg"

function Pb() {
    return (
        <div className='min-h-screen pt-20 pb-20'>
            <Navbar active={"pb"} />
            <div className='px-10'>
                <h5 className='text-2xl text-blue-900 font-semibold mb-2'>Psychosoziale Beratung</h5>
                <p className='text-blue-900 font-normal'>
                    Als Psychosoziale Beraterin begleite ich Sie empathisch, zielorientiert und gestalterisch im Bearbeitungsprozess Ihres aktuellen Beratungsthemas.
                    Ihre eigenen Bemühungen und Kompetenzen werden dabei gestärkt und als selbstwirksame Hilfe zu Ihrer aktuellen Problem- und Lebensbewältigung eingesetzt. Sie entwickeln sich weiter als Person, in Ihrem Verhalten und Erleben. Dies hat unmittelbar positive Auswirkungen auf Ihre Befindlichkeit, Ihre Beziehungen und Ihre Lebensgestaltung. Der Fokus der Beratungen liegt auf selbstbestimmtem, eigenverantwortlichen, sowie gemeinschaftsbezogenem Denken, Fühlen und Handeln.
                </p>
                <p className='text-blue-900 font-normal'>
                    Sobald Ihr gestecktes Ziel erreicht -, Ihre Fragestellung geklärt ist, Sie neu aufgerichtet sind, um Ihr Leben selbstwirksam gestalten und bewältigen zu können, freue ich mich mit Ihnen und wir werden gemeinsam feiern!
                </p>
                <p className='text-blue-900 font-normal mt-5'>
                    <span className='text-blue-950 font-medium inline-block'>Vorbeugen ist besser als heilen: </span> Mit der aktiven Auseinandersetzung lebensbehindernden Themen beugen Sie einer sich allenfalls entwickelnden Symptombildung vor (Krankheits-Prophylaxe). Sie schenken damit Ihrer Ganzheitlichkeit die notwendige Aufmerksamkeit.
                </p>
                <div className='mt-8 flex items-center'>
                    <img src={BildPerle} className='inline-block md:w-40 w-10'></img>
                    <div className='ml-5'>
                        <h6 className='italic text-blue-900 text-xl mb-5'>
                            Meine Leidenschaft ist es, ermutigend an der Seite von und mit Menschen unterwegs zu sein. Gemeinsam mit ihnen zu entdecken, welche Perlen in ihnen stecken, diese zu bestaunen und gewinnbringend in ihrem Leben einzusetzen.
                        </h6>
                        <h6 className='italic text-blue-900 text-xl'>
                            Leben fördern – damit mehr Leben ins Leben kommt.
                        </h6>
                    </div>
                </div>
                <h2 className='mt-8 text-blue-900 font-semibold text-2xl mb-2'>Themenbereiche wie ...</h2>
                <div className='md:flex'>
                    <div>
                        <ul className=''>
                            <li className='text-blue-900 font-normal text-lg'>∼ Eigene Identität stärken – Ressourcen entdecken und einsetzen</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Lebens- und Sinnfragen – was ist mir wichtig – wohin will ich?</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Beziehungsgestaltung und Konfliktbewältigung – Wie setze ich mich gewinnbringend ein?</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Biographie-Arbeit mit Verarbeitungsprozessen – Vergangenes bewältigen und Zukunft gestalten</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Krisenmanagement – eigene Stärken entdecken und nutzen</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Veränderungen – Übergänge stehen bevor – neue Ziele?</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Verlusterfahrungen/Trauer – wie soll es weitergehen?</li>
                            <li className='text-blue-900 font-normal text-lg'>∼ Burnout – Prophylaxe – Selbstfürsorge</li>
                        </ul>
                    </div>
                    <img src={Beratungsraum} className='w-64 object-contain mx-auto md:block mt-10 md:mt-0'></img>
                </div>
                <h2 className='mt-8 text-blue-900 font-semibold text-2xl mb-2'>Beratungsansatz</h2>
                <p className='text-blue-900 font-normal'>
                    Eine Vielfalt von psychologischen Grundkonzepten bieten die Grundlage meiner Beratungskompetenz.
                </p>

                <ul className="list-disc mt-4">
                    <li className='mb-2'>
                        <img src={farbigerPunkt} className="w-5 inline-block object-contain align-middle mr-2" alt="" />
                        <span className='text-blue-900 font-normal'>Die <span className='font-medium'>psychoanalytisch orientierte Beratung</span> geht davon aus, dass weit zurückliegende, emotional berührende Erlebnisse das gegenwärtige Erleben prägen. → Integration der eigenen Biographie und Verantwortungsübernahme.</span>
                    </li>
                    <li className='mb-2'>
                        <img src={farbigerPunkt} className="w-5 inline-block object-contain align-middle mr-2" alt="" />
                        <span className='text-blue-900 font-normal'>Die <span className='font-medium'>klientenzentrierte Beratung </span>stellt den Beziehungsaspekt zwischen der Beraterin und den Ratsuchenden ins Zentrum, als Basis zu einem wachstumsfördernden Klima. → Förderung der Selbsterkenntnis und des Selbstbewusstseins.</span>
                    </li>
                    <li className='mb-2'>
                        <img src={farbigerPunkt} className="w-5 inline-block object-contain align-middle mr-2" alt="" />
                        <span className='text-blue-900 font-normal'>Die <span className='font-medium'>Verhaltenstheoretische Beratung </span>geht davon aus, dass alles Verhalten erlernt und auch wieder verlernt bzw. modifiziert werden kann. → Veränderung von problematischen Denk- und Verhaltensmustern.</span>
                    </li>
                    <li className='mb-2'>
                        <img src={farbigerPunkt} className="w-5 inline-block object-contain align-middle mr-2" alt="" />
                        <span className='text-blue-900 font-normal'>Der <span className='font-medium'>Systemische Ansatz</span> bezieht sich darauf, dass Menschen in sozialen Gefügen leben und dynamische Systeme bilden.
                            → Beziehungen im System stehen im Vordergrund.</span>
                    </li>
                    <li className='mb-2'>
                        <img src={farbigerPunkt} className="w-5 inline-block object-contain align-middle mr-2" alt="" />
                        <span className='text-blue-900 font-normal'>Der <span className='font-medium'>weisheitlich-integrative Ansatz</span> besagt, dass sich die psychologischen Methoden und die Spiritualität verbinden lassen und ergänzen. Der christliche Glaube kann auf Wunsch der ratsuchenden Person im Beratungsprozess mit einbezogen werden. → Ganzheitlichkeit des Menschen.</span>
                    </li>
                    <li className='mb-2'>
                        <img src={farbigerPunkt} className="w-5 inline-block object-contain align-middle mr-2" alt="" />
                        <span className='text-blue-900 font-normal'>Der <span className='font-medium'>gestalttherapeutische Ansatz</span> gründet auf einer erfahrensorientierter - und erlebnisaktivierender Basis. Mit kreativen Mitteln wie Bilder, Gegenstände, Kernsätzen etc. können Ratsuchende Gefühle, Konflikte oder Erlebnisse in Belastungssituationen ausdrücken und vergegenwärtigen. → Person als Ganzheit im Mittelpunkt, wie das Erleben von erhöhter Selbstwirksamkeit und Selbstregulation.</span>
                    </li>
                </ul>
                <div className='md:grid grid-cols-2'>
                    <div>
                        <h2 className='mt-8 text-blue-900 font-semibold text-2xl mb-2'>Ethik & Qualitätssicherung</h2>
                        <p className='text-blue-900 font-normal'>Schweigepflicht</p>
                        <p className='text-blue-900 font-normal'>Supervidierte Praxis</p>
                        <p className='text-blue-900 font-normal'>Regelmässige Weiterbildung</p>
                        <p className='text-blue-900 font-normal'>Mitglied Berufsverband <a href='https://sgfb.ch/' className='underline'>SGfB</a></p>
                        <p className='text-blue-900 font-normal'>Ethische Richtlinien gemäss <a href='https://sgfb.ch/' className='underline'>SGfB</a> / <a href='https://www.bcb-schweiz.ch/' className='underline'>bcb</a></p>
                    </div>
                    <div >
                        <img src={bcbLogo} className='w-40 inline-block mr-16' />
                        <img src={sgfb_stempel} className='w-40 h-40 mr-10 mt-10 inline-block' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pb