import React from 'react'
import { Link } from 'react-router-dom'

function Navbar({ active }) {
    return (
        <div className='flex py-3 items-center justify-center fixed top-0 w-full bg-gray-300/80 backdrop-blur-lg z-50 shadow-white'>
            <div className='justify-self-center'>
                {active == 'home' ? <Link to="/" className='mr-5 font-bold text-blue-950 underline'>Home</Link> : <Link to="/" className='mr-5 font-bold text-blue-950'>Home</Link>}
                {active == 'pb' ? <Link to="/pb" className='mr-5 font-bold text-blue-950 underline'>Psychosoziale Beratung</Link> : <Link to="/pb" className='mr-5 font-bold text-blue-950'>Psychosoziale Beratung</Link>}
                {active == 'about' ? <Link to="/about" className='mr-5 font-bold text-blue-950 underline'>Über mich</Link> : <Link to="/about" className='mr-5 font-bold text-blue-950'>Über mich</Link>}
            </div>
        </div>
    )
}

export default Navbar