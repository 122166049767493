import Folge_deiner_Spur from "../assets/Folge_deiner_Spur.jpg"
import Navbar from '../components/Navbar';

function Home() {
    return (
        <div className='min-h-screen bg-gradient-to-b from-green-50 to-green-100 pt-40 pb-40'>
            <Navbar active={"home"} />
            <h1 className="text-center mb-20 text-6xl font-light text-green-700">Herzlich willkommen!</h1>
            <div className="md:grid grid-cols-2">
                <div className='flex md:justify-end justify-center items-center'>
                    <img src={Folge_deiner_Spur} className='w-80 border-emerald-800 border-solid border-2 md:mr-10'></img>
                </div>
                <div className='flex justify-center items-center md:block mt-10 md:mt-0 md:pt-10'>
                    <div className='md:text-left text-center w-80'>
                        <h2 className='text-2xl text-center text-blue-950'>Beratung & Begleitung</h2>
                        <h2 className='text-2xl text-center text-blue-950' >Coaching</h2>
                        <h2 className='text-2xl text-center text-blue-950'>im Psychosozialen Bereich</h2>
                        <h2 className='text-2xl text-center text-blue-950'>Susanne Knöri</h2>
                    </div>
                </div>
            </div>
            <div className="mt-24 flex flex-wrap justify-center">
                <div className="md:grid grid-cols-5 w-5/12 ">
                    <div>
                        <h1 className="text-center mb-10 lg:text-3xl md:text-xl text-3xl font-light text-green-700 mt-10">Ankommen</h1>
                        <h5 className='text-center'>angenommen</h5>
                        <h5 className='text-center'>wertvoll</h5>
                        <h5 className='text-center'>selbstbewusst</h5>
                        <h5 className='text-center'>ich bin mich</h5>
                    </div>
                    <h1 className="text-center mb-10 lg:text-3xl md:text-xl text-3xl  font-light text-green-700 mt-10"> - </h1>
                    <div>
                        <h1 className="text-center mb-10 lg:text-3xl md:text-xl text-3xl font-light text-green-700 mt-10">Ausruhen</h1>
                        <h5 className='text-center'>durchatmen</h5>
                        <h5 className='text-center'>auftanken</h5>
                        <h5 className='text-center'>geniessen</h5>
                        <h5 className='text-center'>sein</h5>
                    </div>
                    <h1 className="text-center mb-10 lg:text-3xl md:text-xl text-3xl font-light text-green-700 mt-10"> - </h1>
                    <div>
                        <h1 className="text-center mb-10 lg:text-3xl md:text-xl  text-3xl font-light text-green-700 mt-10">Aufleben</h1>
                        <h5 className='text-center'>erstarken</h5>
                        <h5 className='text-center'>aufstehen</h5>
                        <h5 className='text-center'>handeln</h5>
                        <h5 className='text-center'>gestalten</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home